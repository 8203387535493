import { Injectable } from '@angular/core';

declare var ym: any; // Declare ym as any

@Injectable({
  providedIn: 'root',
})
export class YandexMetrikaService {
  constructor() {}

  initMetrika(): void {
    // (function (m, e, t, r, i, k, a) {
    //   m[i] = m[i] || function () {
    //     (m[i].a = m[i].a || []).push(arguments);
    //   };
    //   m[i].l = new Date().getDate();
    //   k = e.createElement(t);
    //   a = e.getElementsByTagName(t)[0];
    //   k.async = 1;
    //   k.src = r;
    //   a.parentNode.insertBefore(k, a);
    // })(
    //   window,
    //   document,
    //   'script',
    //   'https://mc.yandex.ru/metrika/tag.js',
    //   'ym'
    // );
    //
    // // Now TypeScript knows about ym, so this should work without errors
    // ym(87579888, 'init', {
    //   clickmap: true,
    //   trackLinks: true,
    //   accurateTrackBounce: true,
    //   webvisor: true,
    //   ecommerce: 'mkrezka',
    // });
  }
}
