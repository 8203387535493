import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ConsultService} from './consult.service';
import {COUNTRIES_CODE} from '../../../../constants/countries.code.constants';
import {DialogService} from '../../../../service/dialog.service';
import {ConsultModalComponent} from "../../../modals/consult-modal/consult-modal.component";
import {DataStateEnum} from "../../../../enum/data-state.enum";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "../../../../shared/shared.module";

@Component({
  selector: 'app-consult',
  templateUrl: './consult.component.html',
  styleUrls: ['./consult.component.css'],
  standalone: true,
  imports: [SharedModule]
})
export class ConsultComponent implements OnInit {

  form: UntypedFormGroup;
  private successInfo = 'Наш специалист свяжется с вами в скором времени';
  private failureInfo = 'Ошибка, попробуйте еще раз';

  constructor(private fb: UntypedFormBuilder,
              private consultService: ConsultService,
              private modalService: NgbModal,
              private dialogService: DialogService) {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(32)]),
      phoneNumber: new UntypedFormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(13)]),
      countryCode: new UntypedFormControl('', [Validators.required]),
    });
  }

  public countries = COUNTRIES_CODE;


  ngOnInit(): void {
  }

  openModal() {
    this.modalService.open(ConsultModalComponent, {
      size: 'md',
      ariaLabelledBy: 'size-modal',
      centered: true,
      windowClass: ''
    }).result.then((result) => {
      `Result ${result}`
    }, (result) => {
      if (result?.dataState == DataStateEnum.LOADED_STATE) {
        // this.infoModalService.success({message: 'Заказ на звонок принят!'});
      }
      if (result?.dataState === DataStateEnum.ERROR_STATE) {
        // this.infoModalService.success({
        //   message: 'К сожалению, произошла ошибка при обработке вашего запроса на ' +
        //       'звонок. Пожалуйста, попробуйте ещё раз позже или свяжитесь с нами напрямую.!'
        // });
      }
    });
  }

  saveConsultOrder(): void {
    // this.appState$ = this.consultService.save$({
    //   name: this.form.value.name,
    //   phoneNumber: '+' + this.form.value.countryCode + this.form.value.phoneNumber
    // } as Consult)
    //   .pipe(
    //     map(response => {
    //       this.form.reset();
    //       this.dialogService.openInfoDialogPassData(InfoModalComponent, this.successInfo, false);
    //       return {dataState: DataStateEnum.LOADED_STATE, appData: response}
    //     }),
    //     startWith({
    //       dataState: DataStateEnum.LOADING_STATE
    //     }),
    //     catchError((error: string) => {
    //       this.dialogService.openInfoDialogPassData(InfoModalComponent, this.failureInfo, false);
    //       return of({dataState: DataStateEnum.ERROR_STATE, error})
    //     }));
    //
    // this.appState$.subscribe();
  }

  numericOnly(event: { key: string; }): boolean {
    let patt = /^([0-9])$/;
    return patt.test(event.key);
  }

}

export interface Consult {

  name: string;
  phoneNumber: string;
}

