<!--<app-breadcrumb [title]="'About-us'" [breadcrumb]="'About-us'"></app-breadcrumb>-->
<!-- about section start -->

<section class="about-page  section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h4>Политика конфиденциальности и обработки персональных данных</h4>
            </div>
            <div class="col-sm-12">
                <h3>1. Предоставление информации Клиентом:</h3>
                <p>1. Предоставление данных пользователем:
                <p>1.1. В процессе заполнения запроса на веб-сайте https://www.mkrezka.ru/ через форму обратной связи
                    пользователь предоставляет данные, включая фамилию, имя, электронный адрес. При выполнении заказа
                    пользователь может указать дополнительные сведения: фамилию, имя, отчество получателя заказа, адрес
                    доставки, контактный номер телефона. Для активации дисконтных или промо-кодов требуется указать
                    электронную почту.</p>
                <p>
                    1.2. Отправляя свои личные данные, пользователь выражает согласие на их обработку со стороны
                    <b>"Тест"</b>, включая до момента отказа от обработки данных, с целью исполнения обязательств, продажи
                    товаров и услуг, предоставления информации о продукции, а также продвижения товаров и услуг, с
                    уведомлением через рекламные и сервисные сообщения. Обработка персональных данных осуществляется в
                    соответствии с законодательством о персональных данных и рекламе.</p>
                <p>1.2.1. В случае необходимости коррекции данных, их блокировки или удаления по причине их неполноты,
                    устаревания или неправомерного получения, а также для отзыва согласия на их обработку, пользователь
                    должен направить запрос <b>"Тест"</b>. Для удаления учетной записи на сайте следует обратиться с
                    соответствующим запросом на электронный адрес. Это не означает автоматический отзыв согласия на
                    обработку данных.</p>

                <p>1.3. Применение пользовательских данных:</p>
                <p>1.3.1.  <b>"Тест"</b> использует данные пользователя на протяжении всего периода регистрации на сайте для
                    следующих целей:
                    Регистрация и авторизация на сайте;
                    Обработка заказов и выполнение обязательств;</p>

                <div>
                    <ul class="list-group">
                        <li class="list-group-item">Продвижение товаров и услуг;</li>
                        <li class="list-group-item">Анализ функционирования сайта;
                        </li>
                        <li class="list-group-item">Определение победителей акций;</li>
                        <li class="list-group-item">Персонализация рекомендаций;</li>
                        <li class="list-group-item">Информирование о маркетинговых акциях и специальных предложениях.
                        </li>
                    </ul>

                </div>

                <p>1.3.2. <b>"Тест"</b> может отправлять пользователю рекламные сообщения. В случае отказа от подобных
                    уведомлений пользователь может направить отказ на указанный электронный адрес.
                </p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12">
                <h3>2. Раскрытие и передача данных:</h3>
                <p>2.1. <b>"Тест"</b> обязуется не разглашать данные пользователя третьим лицам, кроме как для исполнения
                    обязательств перед пользователем.
                </p>
                <p>2.2. Исключением является предоставление данных по законным требованиям государственных органов..</p>
                <p>2.3. Использование технологии "Cookies" осуществляется без сбора конфиденциальной информации.</p>
                <p>
                    2.4. Сведения об IP-адресе и источнике перехода на сайт собираются без цели идентификации
                    личности.</p>
                <p>2.5. <b>"Тест"</b> не несет ответственности за информацию, размещенную пользователем в открытом доступе.</p>
                <p>2.6. При обработке данных<b>"Тест"</b> принимает все необходимые меры для их защиты от неправомерного
                    доступа.</p>
            </div>

        </div>
    </div>
</section>
<!-- about section end -->