<app-breadcrumb [title]="product?.title" [breadcrumb]="'Product'"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-3">
        </div>
        <div class="col-lg-9 col-sm-12 col-xs-12">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-xl-12">
                <div class="filter-main-btn mb-2">
                  <span class="filter-btn" (click)="toggleMobileSidebar()">
                    <i class="fa fa-bars" aria-hidden="true"></i> sidebar
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar  class="product-slick">
                    <ng-container *ngFor="let url of imageUrls; index as i">
                      <ng-container *ngIf="i == activeSlide">
                        <ng-template carouselSlide>
                          <div>
                            <img
                              lazy-load-image
                              [lazyLoadImageSrc]="imageUrls[activeSlide]"
                              class="img-fluid lazy-loading"
                              alt="{{product.images && product.images[0].alt }}" />
                          </div>
                        </ng-template>
                      </ng-container>
                    </ng-container>
                </owl-carousel-o>
                <div class="col-12 p-0" *ngIf="product?.images?.length">
                    <div class="slider-nav">
                        <owl-carousel-o [options]="ProductDetailsThumbConfig" class="product-slick">
                            <ng-container *ngFor="let url of imageUrls; index as i">
                                <ng-template carouselSlide>
                                    <div class="owl-thumb" [class.active]="i == activeSlide">
                                      <img
                                        lazy-load-image
                                        [lazyLoadImageSrc]="url"
                                        class="img-fluid lazy-loading"
                                        (click)="owlCar.to(activeSlide = i.toString())"
                                        alt="{{product.images && product.images[0].alt }}" />
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
              </div>
              <div class="col-lg-6 rtl-text">
                <div class="product-right">
                  <h2>{{ product?.title }}</h2>
                  <h4>
                      <del *ngIf="product?.discount">{{ (product?.price ?? 0) * (productService.Currency?.price ?? 1) | currency: (productService?.Currency?.currency ?? 'RUB'):'symbol' }}</del>
                      <span> скидка % {{ product.discount }} </span>
                  </h4>
                  <h3 *ngIf="product">
                    {{ (product.price ?? 0) * (productService.Currency?.price ?? 1) | currency: (productService?.Currency?.currency ?? 'RUB'):'symbol' }}
                  </h3>
                  <div>
                    <h6 class="product-title">Материал</h6>
                    <p *ngIf="product?.material">{{ product.material}}</p>
                  </div>
                  <div>
                    <h6 class="product-title">Код товара</h6>
                    <p *ngIf="product?.material">{{ product.product_code}}</p>
                  </div>
                  <div>
                    <h5 class="avalibility" *ngIf="!product.stock"><span>Распродано</span></h5>
                    <h5 class="avalibility" *ngIf="product.stock"><span>В наличии</span></h5>
                  </div>
                  <div class="product-buttons">
                  </div>
                  <div class="border-product">
                    <h6 class="product-title">информация</h6>
                    <p *ngIf="product.description">{{ product.description?.substring(0, 200)+'...' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="tab-product m-0">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink>ОПИСАНИЕ</a>
                        <ng-template ngbNavContent>
                          <p>{{ product?.description }}</p>
                        </ng-template>
                    </li>
                  </ul>
                  <div [ngbNavOutlet]="nav" class="mt-2"></div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>

