import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TileServiceResponse} from './tile-laying-services.component';
import {GetRequestService} from '../../../../service/base/get-reqeust.service';
import { BaseListResponse } from "../../../../../interface/base-response";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TileServiceService {
  private readonly apiUrl = `${environment.appUrl}/services`;

  constructor(private getRequestService: GetRequestService) {
  }

  getTiles(): Observable<BaseListResponse<TileServiceResponse>> {
    return this.getRequestService.getAll(this.apiUrl);
  }
}
