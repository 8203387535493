import {Component, Input, OnInit} from '@angular/core';
import {TileServiceService} from './tile-service.service';
import { SharedModule } from "../../../../shared/shared.module";

@Component({
  selector: 'app-tile-laying-services',
  templateUrl: './tile-laying-services.component.html',
  styleUrls: ['./tile-laying-services.component.css'],
  standalone: true,
  imports: [SharedModule],
})
export class TileLayingServicesComponent implements OnInit {

  @Input('tileServices')
  tileServices: TileServiceResponse[] = [];
  isLoading = true;
  totalItems = 0;

  constructor(private tileService: TileServiceService) {
  }

  ngOnInit(): void {
    this.getTiles();
  }

  getTiles(): void {
    this.isLoading = true;
    this.tileService.getTiles().subscribe(res => {
      if (res.total) {
        this.isLoading = false;
        this.tileServices = res.items || [];
        this.totalItems = res.total;
      }
    });
  }

}


export interface TileServiceResponse {
  name: string;
  price: string;
}

