<!--<app-breadcrumb [title]="'FAQ'" [breadcrumb]="'FAQ'"></app-breadcrumb>-->
<!-- section start-->
<div class="row">
  <div class="col-1"></div>
  <div class="col-10 title1 section-t-space">
    <h2 class="title-inner1">Отзывы</h2>

  </div>
  <div class="col-1"></div>
</div>

<section class="faq-section section-b-space">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="feedback-content">
          <div class="y-feedback" style="width:100%;height:800px;overflow:hidden;position:relative;">
            <iframe style="width:100%;height:100%;border:1px solid #e6e6e6;border-radius:8px;box-sizing:border-box"
                    src="https://yandex.ru/maps-reviews-widget/97586705275?comments"></iframe>
            <a href="https://yandex.ru/maps/org/rezka_plitki_mirkamnya/97586705275/"
               title="Резка плитки - МирКамня на карте Москвы — Яндекс.Карты"
               target="_blank" style="box-sizing:border-box;text-decoration:none;color:#b3b3b3;font-size:10px;font-family:YS Text,sans-serif;padding:0 20px;position:absolute;bottom:8px;width:100%;
  text-align:center;left:0">Резка плитки - МирКамня на карте Москвы — Яндекс.Карты
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->
