import {Injectable} from '@angular/core';
import {Consult} from './consult.component';
import {PostRequestService} from '../../../../service/base/post-reqeust.service';
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConsultService {
  private readonly apiUrl = `${environment.appUrl}/orders`;

  constructor(private postRequestService: PostRequestService) {
  }

  save$ = (consult: Consult) => this.postRequestService.create(consult, this.apiUrl);

}
