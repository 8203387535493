<!--<app-breadcrumb [title]="'FAQ'" [breadcrumb]="'FAQ'"></app-breadcrumb>-->
<!-- section start-->
<section class="section-b-space">
  <div class="container description">
    <div class="row">
      <div class="col-lg-12">
      </div>
      <div class="col-sm-12">
        <h2 class="title text-center">О нос</h2>
        <h6 class="information text-center">Компания МирКамня - это ведущий поставщик услуг по гидроабразивной
          резке плитки,
          керамогранита и камня в Москве и области. Мы имеем богатый опыт и знания в области обработки
          материалов,
          и всегда стремимся предоставить нашим клиентам высококачественные услуги в кратчайшие сроки.
          Наша команда профессионалов обладает высокой квалификацией и опытом работы с различными материалами,
          что позволяет нам эффективно выполнять самые сложные заказы.
          Мы также гарантируем конкурентные цены на все наши услуги и стремимся к полному удовлетворению наших
          клиентов.
          Наша компания является надежным партнером для всех, кто ищет качественную гидроабразивную резку
          материалов.
          Мы предоставляем широкий спектр услуг, включая резку материалов на заказ,
          консультации по выбору наилучшего варианта резки и т.д. Мы работаем только с высококачественным
          оборудованием и материалами,
          что гарантирует точность и качество нашей работы. Мы уверены, что наша компания является лучшим
          выбором для всех,
          кто ищет надежного и профессионального партнера в области гидроабразивной резки материалов.</h6>
      </div>
    </div>
  </div>
</section>
<section class="faq-section section-b-space">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div ngbAccordion >
          <div ngbAccordionItem [collapsed]="false"  *ngFor="let f of faqs; index as i" title="{{f.question}}">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton
                      [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false" [attr.aria-controls]="'flush-collapse'+i">
                {{f.question}}
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="accordion-body">
                {{f.answer}}
              </div>
            </div>
          </div>
        </div>
<!--        <ngb-accordion [closeOthers]="true" activeIds="static-1" class="accordion theme-accordion"-->
<!--                       id="accordionExample">-->
<!--          <ngb-panel *ngFor="let f of faqs; index as i" title="{{f.question}}">-->
<!--            <ng-template ngbPanelContent>-->
<!--              <p class="mb-0">{{f.answer}}</p>-->
<!--            </ng-template>-->
<!--          </ngb-panel>-->
<!--        </ngb-accordion>-->
      </div>
    </div>
  </div>
</section>
<!--Section ends-->
