import {Component, OnInit} from '@angular/core';
import {TeamSlider, TestimonialSlider} from '../../../shared/data/slider';
import { SharedModule } from "../../../shared/shared.module";

@Component({
  selector: 'app-about-us',
  templateUrl: './privacy-information.component.html',
  styleUrls: ['./privacy-information.component.scss'],
  imports: [SharedModule],
  standalone: true,
})
export class PrivacyInformationComponent implements OnInit {

  constructor() { }

  public TeamSliderConfig: any = TeamSlider;
  public TestimonialSliderConfig: any = TestimonialSlider;

  ngOnInit(): void {
  }

}
