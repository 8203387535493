<!--<app-breadcrumb [title]="'Услуги'" [breadcrumb]="'Услуги'"></app-breadcrumb>-->
<!-- about section start -->
<div class="amenities-content">
    <section class="section-b-space testimonial">
        <div class="container description">
            <div class="row">
                <div class="col-lg-12">
                </div>
                <div class="col-sm-12">
                    <h2 class="title text-center">Алмазная резка плитки: современный и точный способ обработки
                        материала</h2>
                    <h6 class="information text-center">Алмазная резка - это современный и точный способ обработки плитки,
                        керамогранита и камня. Этот метод использует алмазные диски, которые обеспечивают быстрое и
                        чистое разрезание материала. В отличие от традиционных методов резки, алмазная резка позволяет
                        получать более гладкие и ровные поверхности без трещин и сколов.
                        Наша компания МК Резка предлагает услуги по алмазной резке плитки для всех типов проектов. Мы
                        используем современное оборудование и технологии, чтобы обеспечить высокую точность и качество
                        резки. Наша команда состоит из опытных специалистов, которые гарантируют эффективную и
                        профессиональную работу.
                        Мы готовы резать плитку любой толщины и размера, включая крупноформатные плитки и керамогранит.
                        Мы также можем резать материалы различной твердости и плотности, включая натуральный камень,
                        мрамор и гранит. Наша алмазная резка плитки подходит для широкого спектра проектов, включая
                        ремонт и отделку помещений, укладку тротуарной плитки, создание фасадов зданий и многое
                        другое.</h6>
                </div>
            </div>
        </div>
    </section>

    <section class="testimonial small-section mt-2">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h2 class="text-2xl font-semibold">Резка плитки и керамогранита</h2>
                    <h4 class="text-base font-light">Гарантия высокого качества по доступным ценам!</h4>
                    <p class="text-xl fs-5">*цена - погон метр от</p>
                </div>
            </div>
        </div>
    </section>

    <section class=" small-section">
        <div class="container bg-white">
            <div class="row gx-3">
                <div *ngFor="let amenity of amenities; let i = index;" class="amenity col-sm-12 col-md-4">
                    <div class="amenity-title">
                        <span class="fs-20">{{amenity.name}}</span>
                    </div>
                    <div class="amenity-text">
                        <div *ngFor="let job of amenity.jobs" class="amenity-text-row">
                            <div class="job-name-col">
                                <p>{{job.name}}</p>
                            </div>
                            <div class="job-price-col">
                                <p>{{job.price}} ₽</p>
                            </div>
                        </div>

                    </div>
                    <div class="calc-price">
                        <button type="button" (click)="orderCall()"><span>Заказать звонок</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


<ng-template #consultModal let-modal>
    <app-consult-modal [modal]="modal" (action)=action()></app-consult-modal>
</ng-template>
