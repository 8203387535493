<div class="consult-content testimonial">
    <div class="form-wrapper">
        <h2 class="form-title semi-bold">
            Выездная резка плитки
        </h2>
        <div class="form-content">
            <div class="form-body">
                <div class="info-tablet">
                    <h3 class="text-white">ВСЕ ВИДЫ УСЛУГ ПО РЕЗКЕ ПЛИТКИ УВАС НА ОБЪЕКТЕ</h3>
                    <h6>Изготовление в день обращения</h6>
                    <h6 class="text-white">• Не знаете как лучше уложить плитку?</h6><br>
                    <h6> Проконсультируем, сделаем замеры, выполним чертеж с раскладкой плитки по помещениям</h6>
                    <h6 class="text-white">• Вывезем плитку на резку и доставить ее обратно</h6>
                </div>
                <form [formGroup]="form" class="needs-validation d-flex flex-column bg-amber-50 p-5 rounded-2xl">
                    <div class="mb-3">
                        <label for="name" class="form-label text-white">Ваше имя</label>
                        <div class="input-group has-validation">
                            <ng-container *ngIf="form.controls['name'] as control">
                                <input type="text" class="form-control" id="name" formControlName="name"
                                       [ngClass]="{ 'is-invalid': control.touched && control.errors, 'is-valid': control.valid }">
                                <div *ngIf="control.touched && control.errors && control.hasError('required')"
                                     class="invalid-feedback">
                                    Поле Страны обязательно
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="d-flex flex-row">
                        <div class="mb-3 me-2">
                            <ng-container *ngIf="form.controls['countryCode'] as control">
                                <label for="name" class="form-label text-white">Страна</label>
                                <select class="form-select" id="country" formControlName="countryCode"
                                        [ngClass]="{ 'is-invalid': control.touched && control.errors, 'is-valid': control.valid }">
                                    <option disabled selected>Страна</option>
                                    <!-- Replace with your countries loop -->
                                    <option *ngFor="let country of countries"
                                            [value]="country.number">{{country.name}}</option>
                                </select>
                                <div *ngIf="control.touched && control.errors && control.hasError('required')"
                                     class="invalid-feedback">
                                    Поле Страны обязательно
                                </div>
                            </ng-container>
                        </div>
                        <div class="mb-3 flex-grow-1">
                            <label for="name" class="form-label text-white">Телефон</label>

                            <ng-container *ngIf="form.controls['phoneNumber'] as control">
                                <input type="number" class="form-control" id="tel" formControlName="phoneNumber"
                                       [ngClass]="{ 'is-invalid': control.touched && control.errors, 'is-valid': control.valid }">
                                <div *ngIf="control.touched && control.errors && control.hasError('required')"
                                     class="invalid-feedback">
                                    Поле телефона обязательно
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <button type="button" class="btn btn-solid text-white w-100" [disabled]="form.invalid">
                        Заказать
                    </button>
                </form>
            </div>
            <div class="info p-5">
                <h3 class="text-white">ВСЕ ВИДЫ УСЛУГ ПО РЕЗКЕ ПЛИТКИ У ВАС НА ОБЪЕКТЕ</h3>
                <h6>Изготовление в день обращения</h6>
                <h6 class="text-white">• Не знаете как лучше уложить плитку?</h6><br>
                <h6> Проконсультируем, сделаем замеры, выполним чертеж с раскладкой плитки по помещениям</h6>
                <h6 class="text-white">• Вывезем плитку на резку и доставить ее обратно</h6>
            </div>
        </div>
    </div>
</div>

