import {Component, OnInit} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import { SharedModule } from "../../../../shared/shared.module";

export interface PeriodicElement {
  name: string;
  material: string;
  weight: number;
  symbol: string;
}

export interface Material {
  name: string;
  priceByWidth: TileWidthPrice[];
}

export interface TileWidthPrice {
  width: string;
  price: string;
}

const ELEMENT_DATA1: PeriodicElement[] = [
  {material: 'Толщина плиты', name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {material: 'Рубль / погон метр', name: 'Helium', weight: 4.0026, symbol: 'He'}
];

const ELEMENT_DATA: Material[] = [
  {name: 'Керамогранит', priceByWidth: [{width: '10 мм', price: '100 руб'}, {width: '20 мм', price: '200 руб'}]},
  {name: 'Мрамор', priceByWidth: [{width: '10 мм', price: '100 руб'}, {width: '20 мм', price: '200 руб'}]},
  {name: 'Гранит', priceByWidth: [{width: '10 мм', price: '100 руб'}, {width: '20 мм', price: '200 руб'}]},
];

@Component({
  selector: 'app-waterjet-cutting-services',
  templateUrl: './waterjet-cutting-services.component.html',
  styleUrls: ['./waterjet-cutting-services.component.css'],
  standalone: true,
  imports: [SharedModule]
})
export class WaterjetCuttingServicesComponent implements OnInit {


  displayedColumns: string[] = ['material', 'name', 'weight', 'symbol'];

  displayedRows: string[] = ['material', 'width', 'price'];
  dataSource = ELEMENT_DATA;

  constructor( private titleService: Title,
               private meta: Meta) {

    this.titleService.setTitle('Гидроабразивная резка плитки в Москве - быстро и без сколов');
    this.meta.updateTag({
        name: 'description',
        content: 'Гидроабразивная резка керамической плитки в Москве. Обращайтесь к нам для быстрой работы без сколов.'
      }
    );
    this.meta.updateTag({
        name: 'keywords',
        content: 'резка плитки в москве, услуга резки плитки в москве, гидроабразивная резка цена, гидроабразивная резка москва, резка плитки москва 45, резка керамической плитки москва'
      }
    );
  }

  ngOnInit(): void {
  }

}
