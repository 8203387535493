import {Routes} from '@angular/router';

import {ProductComponent} from "./item/product.component";
import {ProductsComponent} from "./list/products.component";


const routes: Routes = [
  {
    path: 'product/details/:product',
    component: ProductComponent,
  },
  {
    path: 'collection/left/sidebar/:collectionName',
    component: ProductsComponent,
  },

];

export const shopPagesRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('../../shop/shop.component').then(m => m.ShopComponent),
    children: routes,
  }
];
