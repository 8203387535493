import { Routes } from '@angular/router';
import { pagesRoutes } from "./feature-modules/pages/pages-routing";
import { shopPagesRoutes } from "./feature-modules/pages/products/products.routes";

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./feature-modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'shop',
    children: shopPagesRoutes,
  },
  {
    path: 'pages',
    children: pagesRoutes,
  },
  {
    path: '**',
    loadChildren: () => import('./feature-modules/home/home.module').then(m => m.HomeModule)
  },
];
