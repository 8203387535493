import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { isPlatformBrowser } from "@angular/common";
import { YandexMetrikaService } from "./service/metrika.service";
import { TapToTopComponent } from "./shared/tap-to-top/tap-to-top.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TapToTopComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private yandexMetrikaService: YandexMetrikaService,
  ) {
    if (isPlatformBrowser(this.platformId)) {

    }
  }

  ngOnInit(): void {
    this.yandexMetrikaService.initMetrika();
  }
}
