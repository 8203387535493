<div class="tile-laying-content">
    <section class="section-b-space testimonial">
        <div class="container description">
            <div class="row">
                <div class="col-lg-12">
                </div>
                <div class="col-sm-12">
                    <h2 class="title text-center">Услуги по укладке плитки</h2>
                    <h6 class="information text-center">Компания "МирКамня" предлагает полный спектр услуг по укладке плитки. Мы занимаемся
                        укладкой плитки на полах, стенах, потолках и других поверхностях.

                        Наши специалисты имеют богатый опыт в укладке плитки различных типов и размеров, в том числе керамической,
                        керамогранитной и мраморной. Мы работаем с различными типами поверхностей, включая бетон, штукатурку, гипсокартон
                        и другие.

                        Мы гарантируем высокое качество работы и использование только качественных материалов. При укладке плитки мы
                        учитываем все особенности помещения и предоставляем индивидуальный подход к каждому клиенту.

                        Свяжитесь с нами, чтобы получить профессиональную укладку плитки и создать прекрасный дизайн для вашего дома или
                        офиса.</h6>
                </div>
            </div>
        </div>
    </section>

    <section class="testimonial small-section mt-2">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h2 class="text-2xl font-semibold">Услуги по укладке плитки</h2>
                    <h4 class="text-base font-light">Гарантия высокого качества по доступным ценам!</h4>
                    <p class="text-xl fs-5">*цена - квадрат. метр от</p>
                </div>
            </div>
        </div>
    </section>

    <div class="tile-laying-services">
        <div class="service-row">
            <div class="service">
                <div class="service-text">
                    <div *ngFor="let service of tileServices.slice(0, totalItems / 2)"
                         class="service-text-row">
                        <div class="job-name-col">
                            <p>{{service.name}}</p>

                        </div>
                        <div class="job-price-col">
                            <p>{{service.price}} ₽</p>
                        </div>
                    </div>

                </div>
            </div>

            <div class="service">
                <div class="service-text">
                    <div *ngFor="let service of tileServices.slice(- totalItems / 2)"
                         class="service-text-row">
                        <div class="job-name-col">
                            <p>{{service.name}}</p>

                        </div>
                        <div class="job-price-col">
                            <p>{{service.price}} ₽</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
