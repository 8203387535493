import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ViewportScroller} from '@angular/common';

import {ProductService} from "../product.service";
import {SharedModule} from "../../../../shared/shared.module";
import {GridComponent} from "../../../shop/collection/widgets/grid/grid.component";
import {PaginationComponent} from "../../../shop/collection/widgets/pagination/pagination.component";
import {Product} from "../../../../shared/classes/product";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  standalone: true,
  imports: [SharedModule, GridComponent, PaginationComponent],
})
export class ProductsComponent implements OnInit {

  public grid: string = 'col-xl-3 col-md-6';
  public layoutView: string = 'grid-view';
  public collectionName = '';
  public products: Product[] = [];
  public brands: any[] = [];
  public colors: any[] = [];
  public size: any[] = [];
  public minPrice: number = 0;
  public maxPrice: number = 1200;
  public tags: any[] = [];
  public category: string = '';
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  public sortBy: string = 'asc'; // Sorting Order
  public mobileSidebar: boolean = false;
  public loader: boolean = true;

  constructor(private route: ActivatedRoute, private router: Router,
    private viewScroller: ViewportScroller, public productService: ProductService) {

      // Get Query params..
      this.route.queryParams.subscribe(params => {
        this.products = [];
        this.collectionName = params?.['listName'] || '',
        this.brands = params?.['brand'] ? params?.['brand']?.split(",") : [];
        this.colors = params['color'] ? params?.['color']?.split(",") : [];
        this.size  = params['size'] ? params['size'].split(",")  : [];
        this.minPrice = params['minPrice'] ? params['minPrice'] : this.minPrice;
        this.maxPrice = params['maxPrice'] ? params['maxPrice'] : this.maxPrice;
        this.tags = [...this.brands, ...this.colors, ...this.size]; // All Tags Array


        this.category = params['category'] ? params['category'] : null;
        this.sortBy = params['sortBy'] ? params['sortBy'] : 'ascending';
        this.pageNo = params['page'] ? params['page'] : this.pageNo;

        const payload = {
          page: this.pageNo,
          perPage: this.size,
          sortBy: this.sortBy
        };

        // Get Filtered Products...
        console.log(`Request ${this.collectionName}`)
        this.loader = true;
        this.productService.filterProducts(this.collectionName, payload).subscribe(response => {
          const list = response.items
          this.products = [...list] || [];
          this.loader = false;
          this.paginate = this.productService.getPager(response.totalItems, +this.pageNo, 30);     // get paginate object from service
          console.log('this.paginate', this.paginate)
        }, (error) => {
          this.loader = false;
        })
      })
  }

  ngOnInit(): void {
  }


  // Append filter value to Url
  updateFilter(tags: any) {
    tags.page = null; // Reset Pagination
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: tags,
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // SortBy Filter
  sortByFilter(value: any) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sortBy: value ? value : null},
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Remove Tag
  removeTag(tag: any) {

    this.brands = this.brands.filter(val => val !== tag);
    this.colors = this.colors.filter(val => val !== tag);
    this.size = this.size.filter(val => val !== tag );

    let params = {
      brand: this.brands.length ? this.brands.join(",") : null,
      color: this.colors.length ? this.colors.join(",") : null,
      size: this.size.length ? this.size.join(",") : null
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Clear Tags
  removeAllTags() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // product Pagination
  setPage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Change Grid Layout
  updateGridLayout(value: string) {
    this.grid = value;
  }

  // Change Layout View
  updateLayoutView(value: string) {
    this.layoutView = value;
    if(value == 'list-view')
      this.grid = 'col-lg-12';
    else
      this.grid = 'col-xl-3 col-md-6';
  }

  // Mobile sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

  // TODO - [B] - Move to html for SEO improvement
  navigateDetailsPage(product: Product){
    this.router.navigate([`/shop/product/details/${product.id}`],  {queryParams: { product: JSON.stringify(product)}});
  }

}
