import { afterNextRender, AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {AmenityService} from './amenity.service';
import {Meta, Title} from '@angular/platform-browser';
import {DialogService} from '../../../../service/dialog.service';
import {ConsultModalComponent} from '../../../modals/consult-modal/consult-modal.component';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InfoModalService} from '../../../modals/info-modal/info-modal.service';
import { SharedModule } from "../../../../shared/shared.module";

@Component({
  selector: 'app-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.css'],
  standalone: true,
  imports: [SharedModule, ConsultModalComponent],
})
export class AmenitiesComponent implements OnInit, AfterViewInit {

  @Input('amenities')
  amenities: AmenityResponse[] = [];
  isLoading = true;
  totalItems = 0;

  @ViewChild('consultModal', {static: false}) consultModal: TemplateRef<any> | undefined;

  constructor(private dialogService: DialogService,
              private amenityService: AmenityService,
              private infoModalService: InfoModalService,
              private router: Router,
              private modalService: NgbModal,
              private titleService: Title,
              private meta: Meta) {

    this.titleService.setTitle('Алмазная резка керамогранита в Москве - надежно, быстро и без сколов');
    this.meta.updateTag({
        name: 'description',
        content: 'Качественная алмазная резка керамической плитки в Москве. Обращайтесь к нам для безопасной и быстрой работы без сколов.'
      }
    );
    this.meta.updateTag({
        name: 'keywords',
        content: 'где можно сделать резку плитки +в москве, услуга резки плитки +в москве, алмазная резка цена, алмазная резка москва, резка плитки москва 45, резка керамической плитки цена'
      }
    );

  }

  ngOnInit(): void {
  }


  getAmenities(): void {
    this.isLoading = true;
    this.amenityService.getAmenities().subscribe(res => {
      if (res.total) {
        this.isLoading = false;
        this.amenities = res.items || [];
        this.totalItems = res.total;
      }
    });
  }

  openConsultDialog() {
    this.dialogService.openDialog(ConsultModalComponent);
  }

  action() {

  }

  orderCall() {
    this.amenityService.openOrderCall();
  }

  ngAfterViewInit(): void {
    // afterNextRender(() => {
      this.getAmenities();
    // });
  }
}

export interface AmenityResponse {
  name: string;
  jobs: Job[];
}

export interface Job {

  name: string;
  price: string;
}

