import {Component, OnInit} from '@angular/core';
import { SharedModule } from "../../../shared/shared.module";

interface Faq {
    question: string;
    answer: string;
    isOpen: boolean;
}

@Component({
    selector: 'app-faq',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class FeedbackComponent implements OnInit {


    constructor() {
    }

    ngOnInit(): void {
    }

}
