import {Component, OnInit} from '@angular/core';
import { SharedModule } from "../../../shared/shared.module";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ],
})
export class SearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
