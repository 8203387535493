import {Component, OnInit} from '@angular/core';
import { SharedModule } from "../../../shared/shared.module";

interface Faq {
  question: string;
  answer: string;
  isOpen: boolean;
}
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  standalone: true,
  imports: [SharedModule]
})
export class FaqComponent implements OnInit {

  faqs: Faq[] = [
    {
      question: 'Что такое гидроабразивная резка?',
      answer: 'Гидроабразивная резка - это технология резки материалов, при которой используется вода, смешанная с абразивными частицами. Эта технология позволяет быстро и точно резать различные материалы, такие как плитка, керамогранит и камень.',
      isOpen: false
    },
    {
      question: 'Какие материалы вы можете резать?',
      answer: 'Мы можем резать широкий спектр материалов, включая керамическую плитку, керамогранит, камень, мрамор и т.д. Мы также можем резать материалы на заказ, чтобы соответствовать вашим уникальным потребностям',
      isOpen: false
    },
    {
      question: 'Сколько времени занимает процесс резки?',
      answer: 'Время, затрачиваемое на процесс резки, зависит от сложности задачи и количества материала, который нужно обработать. Мы всегда стараемся выполнить работу в кратчайшие сроки, не снижая качество работы.',
      isOpen: false
    },
    {
      question: 'Как я могу узнать цену на резку материалов?',
      answer: 'Вы можете связаться с нами, чтобы узнать стоимость резки материалов. Мы готовы предоставить бесплатную консультацию и составить индивидуальное коммерческое предложение на основе вашей спецификации.',
      isOpen: false
    },
    {
      question: 'Можете ли вы резать материалы по индивидуальным размерам?',
      answer: 'Да, мы можем резать материалы на заказ, чтобы соответствовать вашим индивидуальным требованиям и размерам. Наша команда экспертов по резке может помочь вам выбрать наилучший вариант резки для вашего проекта.',
      isOpen: false
    },
    {
      question: 'Как я могу заказать резку материалов?',
      answer: 'Вы можете связаться с нами по телефону или электронной почте, чтобы заказать резку материалов. Наша команда ответит на ваши вопросы и предоставит вам дополнительную информацию о наших услугах.',
      isOpen: false
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
