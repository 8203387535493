import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {SharedModule} from "../../../../shared/shared.module";
import {LazyLoadImageDirective} from "../../../../shared/directive/lazy-load-image.directive";
import {Product} from "../../../../shared/classes/product";
import { environment } from "../../../../../environments/environment";
import {ProductDetailsMainSlider, ProductDetailsThumbSlider} from "../../../../shared/data/slider";
import {ProductService} from "../product.service";


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  standalone: true,
  imports: [SharedModule, LazyLoadImageDirective]
})
export class ProductComponent implements OnInit {

  public product: Product = {};
  public counter: number = 1;
  public activeSlide: any = 0;
  public selectedSize: any;
  public mobileSidebar: boolean = false;
  public active = 1;
  imageUrls: string[] = [];
  file_api_url = environment.file_api_url;

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;

  constructor(private route: ActivatedRoute, private router: Router,
    public productService: ProductService) {
    this.route.data.subscribe(response => this.product = response?.['data']);
    this.route.queryParams.subscribe(params => {
      this.product = params['product'] && JSON.parse(params['product']);
      this.productService.getProduct(this.product.collectionName || '', this.product.id || '').subscribe(product => {
        this.product = product;
        this.generateImageUrls();
      })
    });
  }

  ngOnInit(): void {
  }

  generateImageUrls(): void {
    this.imageUrls = this.product?.images?.map(image =>
      `${this.file_api_url}/${this.product.collectionId}/${this.product.id}/${image}?thumb=100x300`
    ) || [];
  }

  // Toggle Mobile Sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

}
