import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, NgStyle, ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-tap-to-top',
  templateUrl: './tap-to-top.component.html',
  styleUrls: ['./tap-to-top.component.scss'],
  standalone: true,
  imports: [
    NgStyle
  ]
})
export class TapToTopComponent implements OnInit {

  public show: boolean = false;

  constructor(private viewScroller: ViewportScroller,
              @Inject(PLATFORM_ID) private platformId: any,) { }

  ngOnInit(): void {
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {

      let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (number > 600) {
        this.show = true;
      } else {
        this.show = false;
      }
    }
  }

  tapToTop() {
  	this.viewScroller.scrollToPosition([0, 0]);
  }

}
