import {Routes} from '@angular/router';
import {SearchComponent} from './search/search.component';
import {ErrorComponent} from './error/error.component';

import {FaqComponent} from './faq/faq.component';

import {AmenitiesComponent} from './cutting-types/amenities/amenities.component';
import {ConsultComponent} from './cutting-types/consult/consult.component';
import {TileLayingServicesComponent} from './cutting-types/tile-laying-services/tile-laying-services.component';
import {
  WaterjetCuttingServicesComponent
} from './cutting-types/waterjet-cutting-services/waterjet-cutting-services.component';
import {FeedbackComponent} from "./feedback/feedback.component";
import {PrivacyInformationComponent} from "./privacy-information/privacy-information.component";

const childRoutes: Routes = [
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: '404',
    component: ErrorComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  {
    path: 'privacy-information',
    component: PrivacyInformationComponent,
  },
  {
    path: 'services/diamond-service',
    component: AmenitiesComponent,
  },
  {
    path: 'services/outside-service',
    component: ConsultComponent,
  },
  {
    path: 'services/tile-lay-service',
    component: TileLayingServicesComponent,
  },
  {
    path: 'services/waterjet-service',
    component: WaterjetCuttingServicesComponent,
  },
]

export const pagesRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages.component').then(m => m.PagesComponent),
    children: childRoutes,
  }
];

