<div class="waterjet-cutting-services-content">

    <section class="section-b-space testimonial w-100">
        <div class="container description">
            <div class="row">
                <div class="col-lg-12">
                </div>
                <div class="col-sm-12">
                    <h2 class="title text-center">Гидроабразивная резка плитки</h2>
                    <h6 class="information text-center">Гидроабразивная резка плитки является одним из самых эффективных
                        способов обработки
                        материала с высокой точностью и чистотой. Этот метод позволяет получить гладкие и ровные края,
                        которые могут быть
                        использованы в различных строительных проектах.
                        Мы используем только современное оборудование и технологии для обеспечения наивысшего качества и
                        точности резки.
                        Наша команда опытных специалистов имеет все необходимые знания и навыки для выполнения работ
                        любой сложности,
                        начиная от простых резов до сложных геометрических форм.</h6>
                </div>
            </div>
        </div>
    </section>

    <div class="price-list">
        <h5 class="two-eight light">Гарантия высокого качества по доступным ценам!</h5>
        <table>
            <tr>
                <td class="td-header-row">Материал</td>
                <td colspan="5" class="td-header-col">Керамогранит / Камни</td>
            </tr>
            <tr>
                <td class="td-header-row">Толщина плиты</td>
                <td class="td-width">10 мм</td>
                <td class="td-width">20 мм</td>
                <td class="td-width">30 мм</td>
                <td class="td-width">40 мм</td>
                <td class="td-width">50 мм</td>
            </tr>
            <tr>
                <td class="td-header-row">Метр погонный</td>
                <td class="td-price">150 руб</td>
                <td class="td-price">400 руб</td>
                <td class="td-price">600 руб</td>
                <td class="td-price">800 руб</td>
                <td class="td-price">1000 руб</td>
            </tr>
        </table>

        <div class="price-list-bottom">
            <h5 class="semi-bold one-eight">Принимается все виды искусственных и натуральных камней, керамогранита и
                керамики.
                При больших объёмах действуют индивидуальные скидки.
                <br/>
                Для подробной информации обращаетесь нашим менеджерам.</h5>
        </div>
    </div>
</div>
