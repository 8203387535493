<app-breadcrumb [title]="collectionName" [breadcrumb]="collectionName"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
<!--                <div class="col-lg-3">-->
<!--                  &lt;!&ndash; TODO - [B] - Should be implemented filter&ndash;&gt;-->
<!--                </div>-->
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="collection-product-wrapper" id="products">
                                    <app-grid [products]="products" [paginate]="paginate" [layoutView]="layoutView" [sortBy]="sortBy" (setGrid)="updateGridLayout($event)" (setLayout)="updateLayoutView($event)" (sortedBy)="sortByFilter($event)">
                                    </app-grid>
                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                                            <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of products">
                                                <div class="product-box">
                                                    <app-product-box-one (clickedEvent)="navigateDetailsPage(product)" [product]="product" [currency]="productService.Currency" [thumbnail]="false" [cartModal]="true" [loader]="true">
                                                    </app-product-box-one>
                                                </div>
                                            </div>
                                            <div class="text-center p-5" *ngIf="loader">
                                              <div class="spinner-border text-dark" role="status">
                                                <span class="sr-only">Loading...</span>
                                              </div>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!products.length && !loader">
                                                <h3>Извините! Не удалось найти то, что вы искали!!! </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <app-pagination [products]="products" [paginate]="paginate" (setPage)="setPage($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->
